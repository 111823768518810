import { memo, useEffect, useMemo, useState } from 'react';

import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { formatEther, getAddress, JsonRpcProvider } from 'ethers';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import UserLayout from '~/components/app-layout/user-layout';
import { IconBtnCopy } from '~/components/IconBtnCopy';
import LoaderCenter from '~/components/loader-center';
import {
  ListNetworksQuery,
  useCountAllQuery,
  useGetMasterWalletAddressQuery,
  useListNetworksQuery,
} from '~/graphql/admin/types';
import { useFirebaseUser } from '~/hooks/with-firebase-auth';

const useStyles = makeStyles()((theme) => ({
  content: {
    height: '100%',
    width: '100%',
  },
  cardContent: {
    padding: 24,
  },
  contentHeader: {
    fontSize: 20,
    fontWeight: 500,
    color: '#333333',
  },
  contentText: {
    fontSize: 16,
    color: '#0000008A',
    fontWeight: 400,
    marginTop: '8px',
  },
}));

const getTitles = (t: Function): { [key: string]: string } => ({
  totalShop: t('dashboard.total_shops'),
  totalAccount: t('dashboard.total_users'),
  totalCollection: t('dashboard.total_collections'),
  totalMemberSite: t('dashboard.total_member_sites'),
  totalOrganization: t('dashboard.total_organizations'),
});

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const firebaseUser = useFirebaseUser();
  const { classes } = useStyles(undefined, { props: {} });

  const [loadingBalances, setLoadingBalances] = useState(true);
  const [balances, setBalances] = useState<Array<ListNetworksQuery['listNetworks'][number] & { balance: string }>>([]);

  const { data: listNetworksRes, loading: loadingSupportedNetwork } = useListNetworksQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { data: countAllRes, loading: loadingCountAll } = useCountAllQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { data: masterWalletAddressRes, loading: loadingMasterWallet } = useGetMasterWalletAddressQuery();

  const supportedNetworks = useMemo(() => listNetworksRes?.listNetworks || [], [listNetworksRes]);
  const masterWalletAddress = masterWalletAddressRes?.getMasterWalletAddress
    ? getAddress(masterWalletAddressRes?.getMasterWalletAddress)
    : '';

  const items = useMemo(() => {
    const titles = getTitles(t);
    const countAll: { [key: string]: number } = countAllRes?.countAll!;
    if (!countAll) {
      return [];
    }
    return Object.keys(countAll).map((key) => ({
      title: titles[key],
      number: countAll[key]?.toLocaleString(),
    }));
  }, [t, countAllRes?.countAll]);

  useEffect(() => {
    document.title = t('dashboard.title');
  }, [t]);

  useEffect(() => {
    if (!!masterWalletAddress) {
      (async () => {
        try {
          setLoadingBalances(true);
          const _balances = await Promise.all(
            supportedNetworks.map(async (network) => {
              const provider = new JsonRpcProvider(network.rpcUrl);
              const balanceBigInt = await provider.getBalance(masterWalletAddress);
              const balance = formatEther(balanceBigInt);
              return { ...network, balance: Number(balance).toFixed(4) };
            })
          );
          setBalances(_balances);
          setLoadingBalances(false);
        } catch {}
      })();
    }
  }, [masterWalletAddress, supportedNetworks]);

  const renderBalances = balances.map((balance) => (
    <Box key={balance.uuid} display="flex" gap={1} padding="4px 0" alignItems="center">
      <img width={32} src={balance.icon || ''} alt="network-icon" />
      <Typography
        variant="subtitle1"
        sx={{ fontSize: '16px', fontWeight: 'bold' }}
      >{`${balance.balance} ${balance.tokenSymbol}`}</Typography>
    </Box>
  ));

  const isLoading = loadingBalances || loadingSupportedNetwork;

  return (
    <UserLayout>
      <Box className={classes.content}>
        <Typography variant="h5">{t('dashboard.welcome', { name: firebaseUser?.displayName })}</Typography>
        <Typography variant="caption">
          {t('dashboard.last_login')}: {moment(firebaseUser?.metadata.lastSignInTime).format(t('date_format'))}
        </Typography>
        <Typography variant="h6" marginTop="32px">
          {t('master_wallet_address')}
        </Typography>
        <Box gap="4px" display="flex" alignItems="center">
          <Typography variant="body1">
            {loadingMasterWallet ? <Skeleton width={380} /> : masterWalletAddress}
          </Typography>
          <IconBtnCopy text={masterWalletAddress || '-'} />
        </Box>
        <Typography variant="h6" marginTop="8px">
          {t('dashboard.balance')}
        </Typography>
        <Box gap="16px" display="flex" alignItems="center">
          {isLoading ? <Skeleton width={150} height={40} animation="wave" /> : renderBalances}
        </Box>
        {loadingCountAll ? (
          <LoaderCenter />
        ) : (
          <Grid container columnSpacing={2} rowSpacing={2} marginTop={2}>
            {items.map((item, key) => (
              <Grid key={key} item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Stack direction="column" spacing={2}>
                      <Typography variant="h6" textAlign="center">
                        {item.title}
                      </Typography>
                      <Typography variant="h3" textAlign="center" color="primary">
                        {item.number.toLocaleString()}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </UserLayout>
  );
};

export default memo(Dashboard);
