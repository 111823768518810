import { FC, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  DialogProps,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CustomDialog from '../custom-dialog';

import { useEditPlanDialogStyles } from './styles';

import { NumberStringTextField } from '~/components/NumberStringTextField';
import { CURRENCY_ICONS, MAX_NUMBER, MAX_STRIPE_PRICE } from '~/constants/common';
import { PlansQueryKey } from '~/enum/common';
import { Currency, GetPlansDocument, useAddPlanMutation, useUpdatePlanMutation } from '~/graphql/admin/types';
import { useNotify } from '~/hooks/useNotify';
import { IPlan } from '~/interfaces/common';
import { getErrorText } from '~/utils/yup.util';

interface IEditPlanDialog extends DialogProps {
  values?: IPlan;
  isEdit?: boolean;
  onClose: () => void;
}

const schema = yup.object({
  [PlansQueryKey.PlanName]: yup.string().max(100).required(),
  [PlansQueryKey.NumberOfShops]: yup.string().maxNumber(MAX_NUMBER).required(),
  [PlansQueryKey.MonthlyFee]: yup.string().maxNumber(MAX_STRIPE_PRICE).required(),
  [PlansQueryKey.NumberOfAdminUsers]: yup.string().maxNumber(MAX_NUMBER).required(),
  [PlansQueryKey.NumberOfMemberSites]: yup.string().maxNumber(MAX_NUMBER).required(),
  [PlansQueryKey.NumberOfMembersPerSite]: yup.string().maxNumber(MAX_NUMBER).required(),
  [PlansQueryKey.AdditionalShopFee]: yup.string().maxNumber(MAX_STRIPE_PRICE).required(),
  [PlansQueryKey.AdditionalSiteFee]: yup.string().maxNumber(MAX_STRIPE_PRICE).required(),
  [PlansQueryKey.AdditionalUserFee]: yup.string().maxNumber(MAX_STRIPE_PRICE).required(),
  [PlansQueryKey.AdditionalMemberFee]: yup.string().maxNumber(MAX_STRIPE_PRICE).required(),
  [PlansQueryKey.NumberOfMembersPerLicense]: yup.string().maxNumber(MAX_STRIPE_PRICE).required(),
});

interface FormValues extends yup.InferType<typeof schema> {}

const EditPlanDialog: FC<IEditPlanDialog> = ({ open, values, onClose }) => {
  const { t } = useTranslation();
  const { showError } = useNotify();
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useEditPlanDialogStyles();

  const isEdit = !!values;

  const convertValue = (value: number | string | undefined) => (!!value || value === 0 ? value.toString() : '');

  const defaultValues = {
    [PlansQueryKey.PlanName]: convertValue(values?.PLAN_NAME),
    [PlansQueryKey.MonthlyFee]: convertValue(values?.MONTHLY_FEE),
    [PlansQueryKey.NumberOfShops]: convertValue(values?.NUMBER_OF_SHOPS),
    [PlansQueryKey.AdditionalUserFee]: convertValue(values?.ADDITIONAL_USER_FEE),
    [PlansQueryKey.AdditionalShopFee]: convertValue(values?.ADDITIONAL_SHOP_FEE),
    [PlansQueryKey.AdditionalSiteFee]: convertValue(values?.ADDITIONAL_SITE_FEE),
    [PlansQueryKey.NumberOfAdminUsers]: convertValue(values?.NUMBER_OF_ADMIN_USERS),
    [PlansQueryKey.AdditionalMemberFee]: convertValue(values?.ADDITIONAL_MEMBER_FEE),
    [PlansQueryKey.NumberOfMemberSites]: convertValue(values?.NUMBER_OF_MEMBER_SITES),
    [PlansQueryKey.NumberOfMembersPerSite]: convertValue(values?.NUMBER_OF_MEMBERS_PER_SITE),
    [PlansQueryKey.NumberOfMembersPerLicense]: convertValue(values?.NUMBER_OF_MEMBERS_PER_LICENSE),
  };

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields, isSubmitting },
  } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [numberOfMembersPerLicense, additionalMemberFee] = useWatch({
    control,
    name: [PlansQueryKey.NumberOfMembersPerLicense, PlansQueryKey.AdditionalMemberFee],
  });

  const isDirty = !!Object.keys(dirtyFields).length;

  const [addPlan] = useAddPlanMutation({
    refetchQueries: [GetPlansDocument],
  });
  const [updatePlan] = useUpdatePlanMutation({
    refetchQueries: [GetPlansDocument],
  });

  const onSubmit = async (data: FormValues) => {
    try {
      const params = {
        planName: data[PlansQueryKey.PlanName],
        uuid: isEdit ? values.UUID : undefined,
        numberOfShops: Number(data[PlansQueryKey.NumberOfShops]),
        additionalUserFee: Number(data[PlansQueryKey.AdditionalUserFee]),
        additionalShopFee: Number(data[PlansQueryKey.AdditionalShopFee]),
        additionalSiteFee: Number(data[PlansQueryKey.AdditionalSiteFee]),
        numberOfAdminUsers: Number(data[PlansQueryKey.NumberOfAdminUsers]),
        additionalMemberFee: Number(data[PlansQueryKey.AdditionalMemberFee]),
        numberOfMemberSites: Number(data[PlansQueryKey.NumberOfMemberSites]),
        monthlyFee: isEdit ? undefined : Number(data[PlansQueryKey.MonthlyFee]),
        numberOfMemberPerSite: Number(data[PlansQueryKey.NumberOfMembersPerSite]),
        numberOfMembersPerLicense: Number(data[PlansQueryKey.NumberOfMembersPerLicense]),
      };
      if (isEdit) {
        await updatePlan({
          variables: {
            input: params,
          },
        });
      } else {
        await addPlan({
          variables: {
            input: {
              ...params,
              currency: Currency.Usd,
              availableForSubscribe: true,
            },
          },
        });
      }
      reset(data);
      onClose();
      enqueueSnackbar(t(isEdit ? 'toast_message.updated_successfully' : 'toast_message.added_successfully'), {
        variant: 'success',
      });
    } catch (err) {
      showError(err);
    }
  };

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, values]);

  return (
    <CustomDialog
      width="md"
      open={open}
      onClose={onClose}
      dialogTitle={t(isEdit ? 'settings.plans_management.edit_plan' : 'settings.plans_management.add_plan')}
      dialogContent={
        <>
          <Stack direction="row" className={classes.section}>
            <Typography className={classes.title}>{t('settings.plans_management.plan_name')}</Typography>
            <Stack className={classes.input}>
              <Controller
                name={PlansQueryKey.PlanName}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    disabled={isSubmitting}
                    error={!!errors[PlansQueryKey.PlanName]?.message}
                    label={t('settings.plans_management.plan_name')}
                    helperText={getErrorText(errors[PlansQueryKey.PlanName]?.message, t)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Divider className={classes.divider} />
          <Stack direction="row" className={classes.section}>
            <Typography className={classes.title}>{t('settings.plans_management.plan_price')}</Typography>
            <Stack className={classes.input}>
              <Controller
                control={control}
                name={PlansQueryKey.MonthlyFee}
                render={({ field }) => (
                  <NumberStringTextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    disabled={isSubmitting || isEdit}
                    label={t('settings.plans_management.plan_price')}
                    error={!!errors[PlansQueryKey.MonthlyFee]?.message}
                    helperText={getErrorText(errors[PlansQueryKey.MonthlyFee]?.message, t)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{CURRENCY_ICONS[Currency.Usd]}</InputAdornment>,
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Divider className={classes.divider} />
          <Stack direction="row" className={classes.section}>
            <Typography className={classes.title}>{t('settings.plans_management.number_of_users')}</Typography>
            <Stack className={classes.input}>
              <Controller
                name={PlansQueryKey.NumberOfAdminUsers}
                control={control}
                render={({ field }) => (
                  <NumberStringTextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    disabled={isSubmitting}
                    error={!!errors[PlansQueryKey.NumberOfAdminUsers]?.message}
                    label={t('settings.plans_management.number_of_free_users')}
                    helperText={getErrorText(errors[PlansQueryKey.NumberOfAdminUsers]?.message, t)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
              <Controller
                name={PlansQueryKey.AdditionalUserFee}
                control={control}
                render={({ field }) => (
                  <NumberStringTextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    disabled={isSubmitting}
                    error={!!errors[PlansQueryKey.AdditionalUserFee]?.message}
                    label={t('settings.plans_management.additional_fee_per_user')}
                    helperText={getErrorText(errors[PlansQueryKey.AdditionalUserFee]?.message, t)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{CURRENCY_ICONS[Currency.Usd]}</InputAdornment>,
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Divider className={classes.divider} />
          <Stack direction="row" className={classes.section}>
            <Typography className={classes.title}>{t('settings.plans_management.number_of_shops')}</Typography>
            <Stack className={classes.input}>
              <Controller
                name={PlansQueryKey.NumberOfShops}
                control={control}
                render={({ field }) => (
                  <NumberStringTextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    disabled={isSubmitting}
                    error={!!errors[PlansQueryKey.NumberOfShops]?.message}
                    label={t('settings.plans_management.number_of_free_shops')}
                    helperText={getErrorText(errors[PlansQueryKey.NumberOfShops]?.message, t)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
              <Controller
                name={PlansQueryKey.AdditionalShopFee}
                control={control}
                render={({ field }) => (
                  <NumberStringTextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    disabled={isSubmitting}
                    error={!!errors[PlansQueryKey.AdditionalShopFee]?.message}
                    label={t('settings.plans_management.additional_fee_per_shop')}
                    helperText={getErrorText(errors[PlansQueryKey.AdditionalShopFee]?.message, t)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{CURRENCY_ICONS[Currency.Usd]}</InputAdornment>,
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Divider className={classes.divider} />
          <Stack direction="row" className={classes.section}>
            <Typography className={classes.title}>{t('settings.plans_management.number_of_member_sites')}</Typography>
            <Stack className={classes.input}>
              <Controller
                name={PlansQueryKey.NumberOfMemberSites}
                control={control}
                render={({ field }) => (
                  <NumberStringTextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    disabled={isSubmitting}
                    label={t('settings.plans_management.number_of_free_sites')}
                    error={!!errors[PlansQueryKey.NumberOfMemberSites]?.message}
                    helperText={getErrorText(errors[PlansQueryKey.NumberOfMemberSites]?.message, t)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
              <Controller
                name={PlansQueryKey.AdditionalSiteFee}
                control={control}
                render={({ field }) => (
                  <NumberStringTextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    disabled={isSubmitting}
                    error={!!errors[PlansQueryKey.AdditionalSiteFee]?.message}
                    label={t('settings.plans_management.additional_fee_per_site')}
                    helperText={getErrorText(errors[PlansQueryKey.AdditionalSiteFee]?.message, t)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{CURRENCY_ICONS[Currency.Usd]}</InputAdornment>,
                    }}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Divider className={classes.divider} />
          <Stack direction="row" className={classes.section}>
            <Stack className={classes.title}>
              <Typography>{t('settings.plans_management.number_of_members_per_site')}</Typography>
              {additionalMemberFee && numberOfMembersPerLicense && (
                <Typography variant="caption">
                  {t('settings.plans_management.corresponding_number_of_members', {
                    count: Number(numberOfMembersPerLicense),
                    fee: additionalMemberFee,
                  })}
                </Typography>
              )}
            </Stack>
            <Stack className={classes.input}>
              <Controller
                name={PlansQueryKey.NumberOfMembersPerSite}
                control={control}
                render={({ field }) => (
                  <NumberStringTextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    disabled={isSubmitting}
                    error={!!errors[PlansQueryKey.NumberOfMembersPerSite]?.message}
                    label={t('settings.plans_management.number_of_free_member_licenses_per_site')}
                    helperText={getErrorText(errors[PlansQueryKey.NumberOfMembersPerSite]?.message, t)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
              <Controller
                name={PlansQueryKey.NumberOfMembersPerLicense}
                control={control}
                render={({ field }) => (
                  <NumberStringTextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    disabled={isSubmitting}
                    error={!!errors[PlansQueryKey.NumberOfMembersPerLicense]?.message}
                    label={t('settings.plans_management.number_of_members_per_license')}
                    helperText={getErrorText(errors[PlansQueryKey.NumberOfMembersPerLicense]?.message, t)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
              <Controller
                name={PlansQueryKey.AdditionalMemberFee}
                control={control}
                render={({ field }) => (
                  <>
                    <NumberStringTextField
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      disabled={isSubmitting}
                      error={!!errors[PlansQueryKey.AdditionalMemberFee]?.message}
                      label={t('settings.plans_management.additional_fee_per_member_license')}
                      helperText={getErrorText(errors[PlansQueryKey.AdditionalMemberFee]?.message, t)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{CURRENCY_ICONS[Currency.Usd]}</InputAdornment>,
                      }}
                      {...field}
                    />
                  </>
                )}
              />
            </Stack>
          </Stack>
        </>
      }
      actions={[
        <Button key={0} variant="outlined" onClick={onClose} disabled={isSubmitting}>
          {t('cancel')}
        </Button>,
        <Button
          key={1}
          variant="contained"
          disabled={isSubmitting || !isDirty}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(onSubmit)}
        >
          {t('submit')}
        </Button>,
      ]}
    />
  );
};

export default EditPlanDialog;
