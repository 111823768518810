import { ChangeEvent, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CustomCardTable from '~/components/custom-card-table';
import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import EditRevenueShareDialog from '~/components/dialog/edit-revenue-share-dialog';
import ListTable from '~/components/list-table';
import GridToolbarSearchByAPI from '~/components/list-table/GridToolbarSearchByAPI';
import WrapperWithFab from '~/components/WrapperWithFab';
import { CURRENCY_ICONS } from '~/constants/common';
import { RevenueShareQueryKey } from '~/enum/common';
import {
  Currency,
  GetRevenueShareDocument,
  OrderBy,
  RevenueShareUnit,
  useGetRevenueShareQuery,
  useRemoveRevenueShareMutation,
} from '~/graphql/admin/types';
import useDebounce from '~/hooks/useDebounce';
import { useNotify } from '~/hooks/useNotify';
import { IRevenueShare } from '~/interfaces/common';
import { getLocalStorage, setLocalStorageItems, verifyOrderKey, verifySortKey } from '~/utils/common';

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    paddingBottom: '60px',
  },
}));

const initQuery = {
  searchText: '',
  orderBy: verifyOrderKey(getLocalStorage('revenue_share_order') || OrderBy.Asc),
  sortBy: verifySortKey(RevenueShareQueryKey, getLocalStorage('revenue_share_sort'), RevenueShareQueryKey.From),
};

const RevenueShare = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { showSuccess, showError } = useNotify();

  const [search, setSearch] = useState('');
  const debounceValue = useDebounce(search, 500);

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState(Currency.Usd);
  const [revenueShareQuery, setRevenueShareQuery] = useState(initQuery);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [processingRevenueShare, setProcessingRevenueShare] = useState(false);
  const [currentRevenueShare, setCurrentRevenueShare] = useState<IRevenueShare>();

  const [removeRevenueShare] = useRemoveRevenueShareMutation({
    refetchQueries: [GetRevenueShareDocument],
  });
  const { data: revenueShareRes, loading: loadingRevenueShare } = useGetRevenueShareQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        currency: currentCurrency,
      },
    },
  });
  const listRevenueShare = useMemo(() => {
    setProcessingRevenueShare(true);
    const revenueShare = revenueShareRes?.getRevenueShare || [];
    const result = revenueShare.map((range, idx) => {
      const nextRange = revenueShare[idx + 1];
      const to = !!nextRange ? nextRange.from - (nextRange.from <= 100 ? 0.01 : 1) : Infinity;
      return {
        id: range.uuid,
        [RevenueShareQueryKey.To]: to,
        [RevenueShareQueryKey.Fee]: range.fee,
        [RevenueShareQueryKey.From]: range.from,
        [RevenueShareQueryKey.Unit]: range.unit,
        [RevenueShareQueryKey.Currency]: range.currency,
        [RevenueShareQueryKey.CreatedAt]: range.createdAt,
      };
    });
    const filtered = result.filter(
      (i) => debounceValue === '' || i[RevenueShareQueryKey.From].toString() === debounceValue.toLowerCase()
    );
    setProcessingRevenueShare(false);
    return filtered;
  }, [revenueShareRes?.getRevenueShare, debounceValue]);

  useEffect(() => {
    document.title = t('settings.revenue_share.title');
  }, [t]);

  useEffect(() => {
    setLocalStorageItems({
      revenue_share_sort: revenueShareQuery?.sortBy,
      revenue_share_order: revenueShareQuery?.orderBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revenueShareQuery?.orderBy, revenueShareQuery?.sortBy]);

  const updateRevenueShareQuery = (newValue: any) => setRevenueShareQuery((value: any) => ({ ...value, ...newValue }));

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenEditDialog = (isCreate = false) => {
    handleCloseMenu();
    setOpenEditDialog(true);
    if (isCreate) {
      setCurrentRevenueShare(undefined);
    }
  };
  const handleCloseEditDialog = () => setOpenEditDialog(false);

  const handleOpenRemoveDialog = () => {
    handleCloseMenu();
    setOpenRemoveDialog(true);
  };
  const handleCloseRemoveDialog = () => setOpenRemoveDialog(false);
  const handleRemoveRevenueShare = async () => {
    try {
      await removeRevenueShare({
        variables: {
          uuid: currentRevenueShare?.id || '',
        },
      });
      showSuccess('toast_message.deleted_successfully');
    } catch (err) {
      showError(err);
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value.replace(/,/g, '');
    setSearch(searchText);
  };

  const handleClickMoreMenu = useCallback(
    (row: IRevenueShare): MouseEventHandler<HTMLButtonElement> =>
      (event) => {
        setAnchorEl(event.currentTarget);
        setCurrentRevenueShare(row);
      },
    []
  );

  const selectCurrentCurrency = (_: React.MouseEvent<HTMLElement>, value: Currency) => {
    setCurrentCurrency(value);
  };

  const columns: GridColDef<IRevenueShare>[] = useMemo(
    () => [
      {
        width: 130,
        headerName: t('from'),
        field: RevenueShareQueryKey.From,
        valueFormatter: ({ value }) =>
          CURRENCY_ICONS[currentCurrency] +
          value.toLocaleString(undefined, { minimumFractionDigits: value <= 100 ? 2 : 0 }),
      },
      {
        width: 130,
        sortable: false,
        headerName: t('to'),
        field: RevenueShareQueryKey.To,
        valueFormatter: ({ value }) =>
          isFinite(value) ? CURRENCY_ICONS[currentCurrency] + value.toLocaleString() : '∞',
      },
      {
        width: 130,
        sortable: false,
        headerName: t('fee'),
        field: RevenueShareQueryKey.Fee,
        renderCell: ({ row, value }) =>
          row.UNIT === RevenueShareUnit.Percent
            ? `${value.toLocaleString()}%`
            : `${CURRENCY_ICONS[row.UNIT]}${value.toLocaleString()}`,
      },
      {
        width: 120,
        type: 'date',
        headerName: t('created_at'),
        field: RevenueShareQueryKey.CreatedAt,
        valueFormatter: ({ value }) => {
          return value ? moment(value).format(t('date_format')) : '-';
        },
      },
      {
        width: 140,
        headerName: '',
        type: 'actions',
        field: t('actions'),
        disableReorder: true,
        renderCell: (params) => {
          return [
            <IconButton key="1" size="small" onClick={handleClickMoreMenu(params.row)}>
              <MoreVertIcon />
            </IconButton>,
          ];
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, currentCurrency]
  );

  const toolbarProps = {
    search,
    searchLabel: `${t('from')}...`,
    handleSearch,
  };

  const renderCurrenciesList = [Currency.Usd, Currency.Jpy, Currency.Eur].map((currency) => {
    return (
      <ToggleButton
        value={currency}
        sx={{ padding: '4px 16px' }}
      >{`${currency} ${CURRENCY_ICONS[currency]}`}</ToggleButton>
    );
  });

  return (
    <Box className={classes.wrapper}>
      <Menu
        id="basic-menu"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleOpenEditDialog()} data-testid="DeleteMenu">
          {t('edit')}
        </MenuItem>
        <MenuItem onClick={handleOpenRemoveDialog} data-testid="DeleteMenu">
          {t('delete')}
        </MenuItem>
      </Menu>
      <WrapperWithFab onClick={() => handleOpenEditDialog(true)}>
        <CustomCardTable
          cardTitle={t('settings.revenue_share.title')}
          headerAction={
            <ToggleButtonGroup value={currentCurrency} exclusive onChange={selectCurrentCurrency}>
              {renderCurrenciesList}
            </ToggleButtonGroup>
          }
          cardContent={
            <Box>
              <ListTable
                noBorder
                columns={columns}
                pagination={false}
                sortingMode="client"
                rows={listRevenueShare}
                tableName="revenueShare"
                rowCount={listRevenueShare.length}
                isLoading={loadingRevenueShare || processingRevenueShare}
                sort={{
                  sortBy: revenueShareQuery.sortBy,
                  orderBy: revenueShareQuery.orderBy,
                }}
                slots={{
                  toolbar: GridToolbarSearchByAPI,
                }}
                slotProps={{
                  toolbar: toolbarProps,
                }}
                onSort={updateRevenueShareQuery}
              />
            </Box>
          }
        />
      </WrapperWithFab>
      <EditRevenueShareDialog
        open={openEditDialog}
        currency={currentCurrency}
        values={currentRevenueShare}
        onClose={handleCloseEditDialog}
      />
      <ConfirmationDialog
        open={openRemoveDialog}
        title={t('settings.revenue_share.delete')}
        onClose={handleCloseRemoveDialog}
        onConfirm={handleRemoveRevenueShare}
      />
    </Box>
  );
};

export default RevenueShare;
