import { env } from '~/env';
import { Currency, OrganizationStatus } from '~/graphql/admin/types';

export const ITEMS_PER_PAGE = 10;
export const MAX_NUMBER = 1000000000000000;
export const MAX_STRIPE_PRICE = 999999999999;
export const API_MEDIA = env.REACT_APP_API_MEDIA + '/';
export const URL_REGEX = /https:\/\/(www.)?[A-Za-z0-9-]+(\.[A-Za-z]{2,}){1,3}\/?[^\s]*$/;

export const CHIP_COLOR: {
  [key: string]: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
} = {
  [OrganizationStatus.Blocked]: 'error',
  [OrganizationStatus.Approved]: 'success',
  [OrganizationStatus.Requested]: 'warning',
};

export const CURRENCY_ICONS: { [key: string]: string } = {
  [Currency.Jpy]: '¥',
  [Currency.Usd]: '$',
  [Currency.Eur]: '€',
};
