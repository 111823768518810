import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { DialogProps } from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import { initRoleOptions } from '../add-member-dialog';
import CustomDialog from '../dialog/custom-dialog';

import { ADMIN_ACTIONS } from '~/config/roleConfig';
import { AdminRole, GetMeDocument, ListAdminDocument, useUpdateAdminMutation } from '~/graphql/admin/types';
import { useAccount } from '~/hooks/with-account';
import { AdminQueryKey, IAdmin, additionalAdministrator } from '~/pages/admin-management';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()(() => ({
  dialog: {
    '.MuiDialog-paper': {
      '& > .MuiIconButton-root': {
        top: '14px',
      },
      '.dialog-title': {
        padding: '16px',
        fontColor: '#444444',
        fontSize: '24px',
        fontWeight: '400',
        lineHeight: '133.4%',
      },
      '.MuiDivider-root': {
        margin: '0 16px',
        borderColor: '#9E9E9E',
      },
      '.MuiDialogContent-root': {
        padding: '16px',
        marginTop: '16px',
        '.MuiFormControl-root': {
          margin: 0,
          ':not(:first-of-type)': {
            marginTop: '23px',
          },
          '.MuiFormHelperText-root.Mui-error': {
            whiteSpace: 'pre-wrap',
          },
        },
      },
      '.MuiDialogActions-root': {
        padding: '16px',
        '.MuiButtonBase-root:not(:first-of-type)': {
          marginLeft: '16px',
        },
      },
    },
  },
}));

interface Props extends DialogProps {
  memberInfo?: IAdmin;
  classes?: StyledComponentProps<typeof useStyles>['classes'] & DialogProps['classes'];
  onClose: () => void;
}

const schema = yup.object({
  role: yup.mixed<AdminRole>().oneOf(Object.values(AdminRole)).required(),
});

interface FormValues extends yup.InferType<typeof schema> {}

const ChangeMemberRole: React.FC<Props> = (props) => {
  const { open, memberInfo, onClose } = props;

  const { t } = useTranslation();
  const { account } = useAccount();

  const [updateAdmin] = useUpdateAdminMutation({
    refetchQueries: [GetMeDocument, ListAdminDocument],
  });

  const memberRole = memberInfo?.[AdminQueryKey.Role];

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      role: !!memberRole ? memberRole : AdminRole.Operator,
    },
    resolver: yupResolver(schema),
  });

  const isDirty = !!Object.keys(dirtyFields).length;

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    async (data: FormValues) => {
      if (!memberInfo?.id) {
        return;
      }
      try {
        await updateAdmin({
          variables: {
            input: {
              role: data.role,
              uuid: memberInfo.id || '',
            },
          },
        });
        enqueueSnackbar(t('toast_message.updated_successfully'), { variant: 'success' });
        onClose();
        reset({ role: data.role });
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [memberInfo?.id, t, updateAdmin, reset, onClose, enqueueSnackbar]
  );

  const roleOptions = useMemo(() => {
    const isCurrentUser = memberInfo?.[AdminQueryKey.Email] === account?.email;
    const isAdministrator = memberInfo?.[AdminQueryKey.Role] === AdminRole.Administrator;
    if (!account) {
      return null;
    }
    const listEditableRoles = additionalAdministrator(
      isCurrentUser && isAdministrator,
      ADMIN_ACTIONS.EDIT[account.role]
    );
    return initRoleOptions(t).reduce((result, option) => {
      if (account && listEditableRoles?.includes(option.value)) {
        result.push(
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        );
      }
      return result;
    }, [] as ReactNode[]);
  }, [account, memberInfo, t]);

  useEffect(() => {
    if (open) {
      reset({ role: memberRole || AdminRole.Operator });
    }
  }, [open, memberRole, reset]);

  return (
    <CustomDialog
      width="md"
      open={open}
      onClose={onClose}
      dialogTitle={t('change_role')}
      dialogContent={
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              label={t('role')}
              variant="outlined"
              margin="normal"
              error={!!errors.role?.message}
              helperText={t(errors.role?.message as any)}
              {...field}
              select
              disabled={isSubmitting}
            >
              {roleOptions}
            </TextField>
          )}
        />
      }
      actions={[
        <Button disabled={isSubmitting} color="primary" variant="outlined" onClick={onClose}>
          {t('cancel')}
        </Button>,
        <Button
          color="primary"
          variant="contained"
          disabled={!isDirty || isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(onSubmit)}
        >
          {t('save')}
        </Button>,
      ]}
    />
  );
};

export default ChangeMemberRole;
