import { FC, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, DialogProps, InputAdornment, MenuItem, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CustomDialog from '../custom-dialog';

import { NumberStringTextField } from '~/components/NumberStringTextField';
import { CURRENCY_ICONS, MAX_NUMBER } from '~/constants/common';
import { RevenueShareQueryKey } from '~/enum/common';
import {
  Currency,
  GetRevenueShareDocument,
  RevenueShareUnit,
  useAddRevenueShareMutation,
  useUpdateRevenueShareMutation,
} from '~/graphql/admin/types';
import { useNotify } from '~/hooks/useNotify';
import { IRevenueShare } from '~/interfaces/common';
import { getErrorText } from '~/utils/yup.util';

interface IEditRevenueShareDialog extends DialogProps {
  isEdit?: boolean;
  currency: Currency;
  values?: IRevenueShare;
  onClose: () => void;
}

const schema = yup.object({
  [RevenueShareQueryKey.Unit]: yup.mixed<RevenueShareUnit>().required(),
  [RevenueShareQueryKey.Fee]: yup.string().maxNumber(MAX_NUMBER).required(),
  [RevenueShareQueryKey.From]: yup.string().maxNumber(MAX_NUMBER).required(),
});

interface FormValues extends yup.InferType<typeof schema> {}

const EditPlanDialog: FC<IEditRevenueShareDialog> = ({ open, values, currency, onClose }) => {
  const { t } = useTranslation();
  const { showError } = useNotify();
  const { enqueueSnackbar } = useSnackbar();

  const isEdit = !!values;

  const convertValue = (value: number | string | undefined) => (!!value || value === 0 ? value.toString() : '');

  const defaultValues = {
    [RevenueShareQueryKey.Fee]: convertValue(values?.FEE),
    [RevenueShareQueryKey.From]: convertValue(values?.FROM),
    [RevenueShareQueryKey.Unit]: values?.UNIT || RevenueShareUnit.Percent,
  };

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields, isSubmitting },
  } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const isDirty = !!Object.keys(dirtyFields).length;

  const unitOptions = useMemo(
    () =>
      Object.values(RevenueShareUnit).map((option: string) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      )),
    []
  );

  const [addRevenueShare] = useAddRevenueShareMutation({
    refetchQueries: [GetRevenueShareDocument],
  });
  const [updateRevenueShare] = useUpdateRevenueShareMutation({
    refetchQueries: [GetRevenueShareDocument],
  });

  const onSubmit = async (data: FormValues) => {
    try {
      const params = {
        currency,
        unit: data[RevenueShareQueryKey.Unit],
        fee: parseFloat(data[RevenueShareQueryKey.Fee]),
        from: parseFloat(data[RevenueShareQueryKey.From]),
      };
      if (isEdit) {
        await updateRevenueShare({
          variables: {
            input: {
              uuid: values.id,
              ...params,
            },
          },
        });
      } else {
        await addRevenueShare({
          variables: {
            input: params,
          },
        });
      }
      reset(data);
      onClose();
      enqueueSnackbar(t(isEdit ? 'toast_message.updated_successfully' : 'toast_message.added_successfully'), {
        variant: 'success',
      });
    } catch (err) {
      showError(err);
    }
  };

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, values]);

  return (
    <CustomDialog
      width="md"
      open={open}
      onClose={onClose}
      dialogTitle={t(isEdit ? 'settings.revenue_share.edit' : 'settings.revenue_share.add')}
      dialogContent={
        <>
          <Controller
            name={RevenueShareQueryKey.From}
            control={control}
            render={({ field }) => (
              <NumberStringTextField
                fullWidth
                decimal={1}
                margin="dense"
                label={t('from')}
                variant="outlined"
                disabled={isSubmitting}
                error={!!errors[RevenueShareQueryKey.From]?.message}
                helperText={getErrorText(errors[RevenueShareQueryKey.From]?.message, t)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{CURRENCY_ICONS[currency]}</InputAdornment>,
                }}
                {...field}
              />
            )}
          />
          <Controller
            name={RevenueShareQueryKey.Unit}
            control={control}
            render={({ field }) => (
              <TextField
                select
                fullWidth
                margin="normal"
                variant="outlined"
                disabled={isSubmitting}
                label={t('settings.revenue_share.unit')}
                error={!!errors[RevenueShareQueryKey.Unit]?.message}
                helperText={getErrorText(errors[RevenueShareQueryKey.Unit]?.message, t)}
                {...field}
              >
                {unitOptions}
              </TextField>
            )}
          />
          <Controller
            name={RevenueShareQueryKey.Fee}
            control={control}
            render={({ field }) => (
              <NumberStringTextField
                fullWidth
                decimal={1}
                margin="normal"
                label={t('fee')}
                variant="outlined"
                disabled={isSubmitting}
                error={!!errors[RevenueShareQueryKey.Fee]?.message}
                helperText={getErrorText(errors[RevenueShareQueryKey.Fee]?.message, t)}
                {...field}
              />
            )}
          />
        </>
      }
      actions={[
        <Button key={0} variant="outlined" onClick={onClose} disabled={isSubmitting}>
          {t('cancel')}
        </Button>,
        <Button
          key={1}
          variant="contained"
          disabled={isSubmitting || !isDirty}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(onSubmit)}
        >
          {t(isEdit ? 'save' : 'add')}
        </Button>,
      ]}
    />
  );
};

export default EditPlanDialog;
